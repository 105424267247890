import React from "react"
import Homepage from "../components/Homepage"
import Layout from "../components/layout"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Homepage />
    </Layout>
  )
}

export default IndexPage
