import React from "react"
import Navbar from "./Navbar.js";
import { GlobalStyle } from "./styles/GlobalStyles.js";

const Layout = ({ children }) => {
    return (
        <>
            <GlobalStyle />
            <Navbar />
            <main>{children}</main>
        </>
    )
}

export default Layout