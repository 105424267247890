import React from 'react'
import styled from 'styled-components'
import { Button } from './CustomButton'
import BgVideo from '../assets/vid/prog_vscode.mp4';

const Homepage = () => {
    return (
        <BgContainer>
            <Bg>
                <VideoContainer src={BgVideo} type="video/mp4" autoPlay loop muted playsInline />
            </Bg>
            <Text>
                <Items>
                    <Header1>This website is amazing!</Header1>
                    <Small>Made with gatsby!</Small>
                    <Button Big="true" Round="true" to="https://github.com/JNDVasco"> Check it!</Button>
                </Items>
            </Text>
        </BgContainer >
    )
}

export default Homepage

const BgContainer = styled.div`
    background: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 1rem;
    position: relative;
    margin-top: -80px;
    color: #fff;

    :before{
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(
                                    0deg,
                                    rgba(0,0,0,0.45) 0%,
                                    rgba(0,0,0,0.85) 100%
                                    ),
                    linear-gradient(
                                    0deg,
                                    rgba(0,0,0,0.4) 0%,
                                    transparent 100%
                                    );
    }
`

const Bg = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const VideoContainer = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`

const Text = styled.div`
    z-index: 5;
    height: calc(100vh - 96px);
    max-height: 100vh;
    padding: 0rem calc((100vw - 1300px)/2);
`

const Items = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    max-height: 100%;
    padding: 0;
    color: #fff;
    line-height: 1.5;
    font-weight: bold;
`
const Header1 = styled.h1`
    font-size: clamp(1.5rem, 6vw, 5rem);
    margin-bottom: 1rem;
    letter-spacing: 2px;
    padding: 0 1rem;
`
const Small = styled.p`
    font-size: clamp(1rem, 3vw, 3rem);
    margin-bottom: 1rem;
    font-weight: 500;
`
