import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { FaBars } from "react-icons/fa";
import { menus } from "../data/Menu";
import { Button } from "./CustomButton";

const Navbar = () => {
    return (
        <Nav>
            <NavLink to="/"> JNDVasco</NavLink>
            <MenuIcon />
            <NavMenu>
                {menus.map((menuItem, key) => (
                    <NavLink to={menuItem.path} key={key}>
                        {menuItem.title}
                    </NavLink>
                ))}
            </NavMenu>
            <NavButton>
                <Button Main="true" Round="true" to="https://github.com/JNDVasco"> GitHub</Button>
            </NavButton>
        </Nav>
    )
}

export default Navbar

const Nav = styled.nav`
    background: transparent;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem calc((100wv-1300px) / 2);
    z-index: 100;
    position: relative;
`
const NavLink = styled(Link)`
    color: #FAFAFA;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 800;
    font-size: clamp(0.5rem, 3vw, 1.5rem);
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
`

const MenuIcon = styled(FaBars)`
    display: none;
    color: #FFF;

    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.5rem;
        cursor: pointer;
    }
`

const NavMenu = styled.div`
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 32px;

    @media screen and (max-width: 768px){
        display: none;
    }

`

const NavButton = styled.div`
    display: flex;
    align-items: center;
    margin-right: 16px;

    @media screen and (max-width: 768px){
        display: none;
    }
`